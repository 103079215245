import React from 'react'
import Layout from '../components/layout'
import ContactForm from '../components/contact-form'
import Nav from '../components/nav'
import SimpleNav from '../components/simple-nav'
import navigation from '../navigation'

export default function ContactUsPage() {
  return (
    <Layout>
      <SimpleNav navigation={navigation} />
      <ContactForm />
    </Layout>
  )
}
